import React from 'react'

import Layout from '../../components/layout'
import Head from '../../components/head'

import Homeofficephotography from './homeofficephotography.jpg'

const HomeofficephotographyPage = () => {
    return (
        <Layout image={Homeofficephotography} footerImageDescription='"Homeoffice" - Staged Photography'>
            <Head title="Homeoffice Photography -"/>
        </Layout>
    )
}

export default HomeofficephotographyPage